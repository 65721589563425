import React from "react"
import styled from "styled-components"

import Divider from "../components/Divider"
import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import { LAYOUT, LINKS } from "../constants"
import individualImage from "../assets/img/signUpDescription/individual@2x.png"
import groupImage from "../assets/img/signUpDescription/team@2x.png"

const DATA = [
  {
    heading: "Sign up as an individual",
    img: individualImage,
    description: (
      <>
        <p>
          Signing up for 5kMay is super simple. Enter your name and email
          address so we can send you the event information and a race pack
          beforehand.
        </p>
        <p>
          If you’d prefer not to sign up - no problem, you can still
          participate. When May arrives, participate in a 5k of your choice
          (run, walk, roll, cycle, swim) and donate £5 to charity{" "}
          <a href={LINKS.DONATE} rel={"noopener"} target={"_blank"}>
            here
          </a>
          .
        </p>
        <p>
          After you have participated and donated, upload a picture to your
          social media and encourage your friends to take part too.
        </p>
        <p>
          Plus, after you’ve donated you’ll unlock a digital goodie bag full of
          rewards and treats from our very cool brand partners. It’s our way of
          saying thank you!
        </p>
      </>
    ),
  },
  {
    heading: "Sign up as a team",
    img: groupImage,
    description: (
      <>
        <p>
          Why not get your colleagues, school or sports club involved too? It’s
          as easy as ABC! Register your interest and we’ll send you all the
          event information you need to share with your team beforehand.
        </p>
        <p>
          After you have signed your team up, you will receive a downloadable
          file that includes a social media pack that you can use to promote
          5kMay across your channels. This is a great way to mobilise your
          colleagues or pupils to get active while raising money for a charity
          of your choice.
        </p>
        <p>
          If you’d prefer not to sign up now, don’t worry you can still get a
          team together. When May arrives, encourage your team to participate in
          5k, either together or virtually. Collect and donate £5 for each
          participant in your team or ask them to donate themselves.
        </p>
        <p>
          Once you’ve donated, you’ll unlock a digital goodie bag full of
          rewards and treats from our very cool brand partners. It’s our way of
          saying thank you!
        </p>
        <p>
          Upload a picture of your team afterwards on social (
          <a href={LINKS.INSTAGRAM} target={"_blank"} rel={"noopener"}>
            remembering to tag us
          </a>
          ) so we can give you a special shout out!
        </p>

        <p>
          Teams include: corporates, charities, schools, universities and sports
          clubs.
        </p>
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const WrapperInner = styled.div`
  max-width: 906px;
  margin: 0 auto;
`

const ContentHeading = styled(H2)`
  margin-bottom: 30px;
`

const Image = styled.img`
  align-self: center;
  max-width: 204px;
  margin-bottom: 40px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 0;
    align-self: flex-start;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
  }
`

const CopyContainer = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-left: 32px;
  }
`

interface ISignUpDescriptions {
  rest?: object
}

const SignUpDescriptions: React.FC<ISignUpDescriptions> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <WrapperInner>
        {DATA.map((data, i) => (
          <div key={i}>
            <Section>
              <Image src={data.img} />
              <CopyContainer>
                <ContentHeading>{data.heading}</ContentHeading>
                <BodyText type={"DARK"}>{data.description}</BodyText>
              </CopyContainer>
            </Section>
            {i === 0 && <Divider />}
          </div>
        ))}
      </WrapperInner>
    </Root>
  )
}

export default SignUpDescriptions
