import React, { useState, useRef } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { H1, H2 } from "../components/Heading"
import BodyText from "../components/BodyText"
import { LAYOUT, FONT_FAMILIES, COLORS, LINKS } from "../constants"

const SUBSCRIPTION_TYPES = {
  INDIVIDUAL: "INDIVIDUAL",
  TEAM: "TEAM",
}

const SUBSCRIPTION_TYPES_FORM_VALUE_MAP = {
  INDIVIDUAL: "Individual",
  TEAM: "Team",
}

const SUCCESS_FEEDBACK = {
  INDIVIDUAL: {
    heading: "You're signed up to #5kMay",
    iconKey: "individual",
    body: (
      <>
        <p>We'll send you all the information you'll need before #5kMay!</p>
      </>
    ),
  },
  TEAM: {
    heading: "Your team is signed up to #5kMay",
    iconKey: "team",
    body: (
      <>
        <p>We'll send you all the information you'll need before #5kMay!</p>
      </>
    ),
  },
}

const Root = styled.div`
  max-width: 906px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Heading = styled(H1)`
  font-family: ${FONT_FAMILIES.RFH_OUTLINE};
  margin-bottom: 50px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 8.5rem;
  }
`

const Form = styled.form`
  width: 670px;
  background: ${COLORS.WHITE};
  width: 100%;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 40px;
  }
`

const FormHeading = styled.div`
  background: ${COLORS.GREY};
  display: flex;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 670px;
  width: 100%;
`

const FormHeadingCopy = styled(BodyText)`
  max-width: 423px;
  padding: 46px 30px 34px;
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 670px;
  box-shadow: 0 0 24px 10px rgba(12, 33, 42, 0.16);
`

const Radio = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  &:focus + div {
    outline: 2px solid ${COLORS.BLUE};
  }
`

interface ITypeImg {
  fluid: object
}

const TypeImg = styled(Img)<ITypeImg>`
  width: 50%;
  margin: 10px auto 20px auto;
  max-width: 204px;
  cursor: pointer;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 100%;
  }
`

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-wrap: nowrap;
  }
`

interface ITypeHeading {
  active: boolean
  hasSubscriptionType: boolean
}
const TypeHeading = styled(BodyText)<ITypeHeading>`
  p {
    font-family: ${FONT_FAMILIES.RFH_REGULAR};
    text-align: center;
    opacity: ${({ active, hasSubscriptionType }) =>
      active || (!active && !hasSubscriptionType) ? "1" : "0.4"};
  }
`

const InputRow = styled.div`
  max-width: 424px;
  margin: 0 auto;
`

const Input = styled.input`
  width: 100%;
  height: 34px;
  font-size: 1.6rem;
  padding: 0 8px;
  border: 1px solid ${COLORS.GREY};
  border-radius: 2px;
  margin-bottom: 24px;
  color: ${COLORS.GREY};
`

const Label = styled.label`
  font-size: 1.6rem;
  color: ${COLORS.GREY};
`

const SubscribeButton = styled.input`
  display: flex;
  align-items: center;
  padding: 0 30px;
  line-height: normal;
  min-height: 40px;
  width: auto;
  background: ${COLORS.GREY};
  color: ${COLORS.WHITE};
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  font-size: 1.8rem;
  text-decoration: none;
  height: 40px;
  border: none;
  margin: 0 auto;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:disabled {
    opacity: 0.3;
  }
`

const SuccessFeedback = styled.div`
  max-width: 670px;
  border-radius: 11px;
  background: ${COLORS.GREY};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 55px 85px;
  }
`

const SuccessFeedbackHeading = styled(H2)`
  margin-bottom: 15px;
  line-height: 1.3;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 15px;
  }
`

const TypeLabel = styled.label`
  width: 100%;
  transition: opacity 0.2s;
  // &:hover {
  //   opacity: 0.6;
  // }
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 45%;
  }
`

const ErrorText = styled(BodyText)`
  margin-top: 20px;
  a,
  p {
    color: ${COLORS.PINK};
  }
`

const AlreadySignedUp = styled(BodyText)`
  margin-top: 25px;
`

interface ISubscribeForm {
  rest?: object
}

const SubscribeForm: React.FC<ISubscribeForm> = ({ ...rest }) => {
  const [firstName, setFirstName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [organisation, setOrganisation] = useState("")
  const [subscriptionType, setSubscriptionType] = useState(null)
  const [responseIsError, setResponseIsError] = useState(null)
  const [responseIsSuccess, setResponseIsSuccess] = useState(null)
  const [response, setResponse] = useState("")
  const formEl = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(
      email,
      {
        FNAME: firstName,
        LNAME: surname,
        SIGNUPAS: SUBSCRIPTION_TYPES_FORM_VALUE_MAP[subscriptionType],
      },
      null
    ).then(data => {
      setResponse(data.msg)
      if (data?.result === "error") {
        setResponseIsError(true)
      } else {
        setResponseIsSuccess(true)
        setResponseIsError(false)
        formEl.current.scrollIntoView({
          behavior: "smooth",
        })
      }
    })
  }

  const imageData = useStaticQuery(graphql`
    query SubscribeFormQuery {
      individual: file(relativePath: { eq: "subscribeForm/individual.png" }) {
        childImageSharp {
          fluid(maxWidth: 204, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      team: file(relativePath: { eq: "subscribeForm/team.png" }) {
        childImageSharp {
          fluid(maxWidth: 204, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const canSubmit = firstName && surname && email && subscriptionType
  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        Sign up for 5kMay
      </Heading>

      {responseIsSuccess ? (
        <SuccessFeedback ref={formEl}>
          <SuccessFeedbackHeading color={"WHITE"} alignment={"CENTER"}>
            {SUCCESS_FEEDBACK[subscriptionType].heading}
          </SuccessFeedbackHeading>
          <TypeImg
            fluid={
              imageData[SUCCESS_FEEDBACK[subscriptionType].iconKey]
                .childImageSharp.fluid
            }
          />
          <BodyText type={"LIGHT"} alignment={"CENTER"}>
            {SUCCESS_FEEDBACK[subscriptionType].body}
          </BodyText>
        </SuccessFeedback>
      ) : (
        <FormWrapper>
          <FormHeading>
            <FormHeadingCopy alignment={"CENTER"}>
              <p>Sign up for our next event: 5kMay</p>
            </FormHeadingCopy>
          </FormHeading>
          <Form>
            <TypeContainer>
              <TypeLabel>
                <TypeHeading
                  type={"DARK"}
                  active={subscriptionType === SUBSCRIPTION_TYPES.INDIVIDUAL}
                  hasSubscriptionType={!!subscriptionType}
                >
                  <p>Sign up as an individual</p>
                </TypeHeading>
                <Radio
                  type="radio"
                  name="type"
                  value="individual"
                  checked={subscriptionType === SUBSCRIPTION_TYPES.INDIVIDUAL}
                  onChange={() => {
                    setSubscriptionType(SUBSCRIPTION_TYPES.INDIVIDUAL)
                  }}
                />
                <TypeImg
                  fluid={imageData.individual.childImageSharp.fluid}
                  style={{
                    opacity:
                      subscriptionType &&
                      subscriptionType !== SUBSCRIPTION_TYPES.INDIVIDUAL
                        ? 0.4
                        : 1,
                  }}
                />
              </TypeLabel>

              <TypeLabel>
                <TypeHeading
                  type={"DARK"}
                  active={subscriptionType === SUBSCRIPTION_TYPES.TEAM}
                  hasSubscriptionType={!!subscriptionType}
                >
                  <p>Sign up as a team</p>
                </TypeHeading>
                <Radio
                  type="radio"
                  name="type"
                  value="team"
                  checked={subscriptionType === SUBSCRIPTION_TYPES.TEAM}
                  onChange={() => {
                    setSubscriptionType(SUBSCRIPTION_TYPES.TEAM)
                  }}
                />
                <TypeImg
                  fluid={imageData.team.childImageSharp.fluid}
                  style={{
                    opacity:
                      subscriptionType &&
                      subscriptionType !== SUBSCRIPTION_TYPES.TEAM
                        ? 0.4
                        : 1,
                  }}
                />
              </TypeLabel>
            </TypeContainer>

            {subscriptionType && (
              <>
                <InputRow>
                  <Label htmlFor={"firstName"}>First name</Label>
                  <Input
                    id={"firstName"}
                    name={"firstName"}
                    type={"text"}
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </InputRow>
                <InputRow>
                  <Label htmlFor="surname">Surname</Label>
                  <Input
                    id={"surname"}
                    name={"surname"}
                    type={"text"}
                    onChange={e => setSurname(e.target.value)}
                    value={surname}
                  />
                </InputRow>
                <InputRow>
                  <Label htmlFor="email">Email address</Label>
                  <Input
                    id={"email"}
                    name={"email"}
                    type={"email"}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </InputRow>
                <InputRow>
                  <Label htmlFor="organisation">Organisation</Label>
                  <Input
                    id={"organisation"}
                    name={"organisation"}
                    type={"text"}
                    onChange={e => setOrganisation(e.target.value)}
                    value={organisation}
                  />
                </InputRow>
                <SubscribeButton
                  disabled={!canSubmit}
                  type={"submit"}
                  onClick={handleSubmit}
                  value={"Sign up"}
                />
                {responseIsError && (
                  <ErrorText type={"DARK"} alignment={"CENTER"}>
                    <p dangerouslySetInnerHTML={{ __html: response }} />
                  </ErrorText>
                )}
              </>
            )}
          </Form>
        </FormWrapper>
      )}
      <AlreadySignedUp>
        <p>
          Already signed up?{" "}
          <a href={LINKS.TOOLKIT} target={"_blank"} rel={"noopener"}>
            Download your participant toolkit
          </a>
        </p>
      </AlreadySignedUp>
    </Root>
  )
}

export default SubscribeForm
