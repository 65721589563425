import React from "react"
import Helmet from "react-helmet"

import "../styles.css"
import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import Footer from "../partials/Footer"
import SignUpDescriptions from "../partials/SignUpDescriptions"
import CookieBanner from "../partials/CookieBanner"
import SubscribeForm from "../partials/SubscribeForm"

const SignUp: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign-up | 5kMay | Run For Heroes</title>
        <meta
          name="description"
          content="Interested in taking part in our next event? Want to know more about us? Sign up to register your interest and keep up to date with our news."
        />
        <html lang="en" />
      </Helmet>

      <Nav activePath={"/sign-up"} />

      <PageSection backgroundColor={"BLUE"}>
        <SubscribeForm />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <SignUpDescriptions />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default SignUp
